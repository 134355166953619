<template>
  <form @submit.prevent="updateProperty">
    <div class="mt-3">
      <button class="btn btn-success" @click="fetchAINotes" :disabled="isFetching">Fetch AI Status</button>

      <p class="mt-2">{{ this.ai_status }}</p>
    </div>
    <div class="mt-3">
      <div>
        <b>FF Notes</b>
      </div>
      <div class="new-note">
        <div class="input-note">
          <textarea rows="4" placeholder="" class="form-control" v-model="note" @focus="disableArrowKey(true)" @blur="disableArrowKey(false)"/>
        </div>
        <div>
          <button class="btn btn-success" @click.prevent="createNote" :disabled="note == ''">Create Note</button>
        </div>
      </div>
      <ul v-for="note in notes">
        <li>
          <span>{{ datePosted(note) }}</span>
          <span v-html="formattedContent(note.content)"></span>
          <span>{{ noteBy(note) }}</span>
          <span class="ml-2">
            <a v-b-modal="`modal-edit-note-${note.id}`">
              <b-icon icon="pencil" class="custom-icon"></b-icon>
            </a>
            <b-modal :id="`modal-edit-note-${note.id}`" title="Edit Note"  @ok="updateNote(note)">
              <div>
                <textarea class="w-100" rows="10" :value="note.content" :ref="`editedNote${note.id}`"/>
              </div>
            </b-modal>
          </span>
          <span class="ml-2">
            <a v-b-modal="`modal-delete-note-${note.id}`">
              <b-icon icon="trash" class="custom-icon"></b-icon>
            </a>
            <b-modal :id="`modal-delete-note-${note.id}`" title="Delete Note"  @ok="deleteNote(note)">
              <p class="my-4">Are you sure want to delete this note?</p>
            </b-modal>
          </span>
        </li>
      </ul>
      <!-- <textarea v-model="property.ff_notes" class="form-control" rows="5" @focus="disableArrowKey(true)" @blur="disableArrowKey(false)">{{ property.ff_notes }}</textarea> -->
    </div>

    <div class="mt-2">
      <b>{{ this.partner }} Notes</b>
      <textarea v-model="property.rm_notes" class="form-control" rows="5" @focus="disableArrowKey(true)" @blur="disableArrowKey(false)">{{ property.rm_notes }}</textarea>
    </div>
    
    <button type="submit" name="commit" class="btn btn-primary mt-2">Update Partner Notes</button>
    
    <div class="mt-2">
      <p v-for="status in underwritingStatuses">
        Property was added to {{ status['name'] }} {{ timeSince(status['created_at']) }} ago
      </p>
    </div>
    
    <div class="mt-2" style="display: none">
      <b>AI Notes - (Reasons why we marked it a particular status)</b>
      <textarea v-model="property.ai_notes" class="form-control" rows="5" @focus="disableArrowKey(true)" @blur="disableArrowKey(false)">{{ property.ai_notes }}</textarea>
    </div>
    
    <div class="mt-2" style="display: none">
      <b>AI Statuses - (Reasons why we marked it a particular status)</b>
      <ul>
        <li v-for="reason in reasons">
          <input type="checkbox" :value="reason" :id="idGenerator(reason)" v-model="selectedReasons" />
          <label :for="idGenerator(reason)">{{ reason }}</label>
        </li>
      </ul>
    </div>
    
  </form>
</template>

<script>
import axios from "axios";

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  props: ['property', 'partner', 'initialReasons', 'underwritingStatuses'],
  data() {
    return {
      reasons: [
        "Low population",
        "Low income",
        "High income",
        "High population",
        "Street retail",
        "Bad area crime",
        "Bad politics",
        "High Alphmaps rating",
        "Bad Alphamaps rating",
        "Receiver in place",
        "Deed in lieu potential",
        "Maturity default",
        "Lease expirations",
        "Value add because vacancy",
        "Disaster because of vacancy",
        "Loan Assumption potential",
        "Price per ft too expensive",
        "Pice per ft is a good deal",
        "Bad ground lease"
      ],
      selectedReasons: this.initialReasons,
      ai_status: '',
      isFetching: false,
      note: '',
      notes: []
    }
  },
  methods: {
    datePosted(note) {
      if (note.user_id != null) {
        return this.formattedDate(note.created_at) + " - "
      }
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    noteBy(note) {
      if (note.user_id != null) {
        return " - " + this.capitalizeFirstLetter(note.user.email.split('@')[0])
      }
    },
    formattedContent(text) {
      return text.replace(/\n/g, '<br>');
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
  
      const month = date.getUTCMonth() + 1; // Months are zero-indexed
      const day = date.getUTCDate();
      const year = date.getUTCFullYear().toString().slice(-2); // Get the last two digits of the year

      return `${month}/${day}/${year}`;
    },
    disableArrowKey(status) {
      this.$store.commit("setArrowKeyDisabled", status)
    },
    idGenerator(string) {
      return string.toLowerCase().split(" ").join("_")
    },
    updateProperty: function() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      const id = this.$route.params.id;
      
      const property =  {
        ff_notes: this.property.ff_notes,
        rm_notes: this.property.rm_notes,
        ai_notes: this.property.ai_notes,
        ai_statuses: this.selectedReasons
      }

      axios.put(`/properties/${id}.json`, { property })
      .then(response => {
        notyf.success('Property notes updated!');
      })
      .catch(error => {
        notyf.error('Oops, notes could not be updated');
      })
    },
    fetchAINotes: function() {
      this.ai_status = "Fetching AI status...";
      
      this.isFetching = true;
      
      const id = this.$route.params.id;

      axios.get(`/ai_statuses/${id}.json`)
      .then(response => {
        console.log(response)
        this.ai_status = response.data.choices.map(c => c.message.content)[0]
        
        this.isFetching = false;
      })
      .catch(error => {
        console.log(error)
        this.isFetching = false;
        
      })
    },
    timeSince: function(date) {
      var current_date = new Date(date)
      
      var seconds = Math.floor((new Date() - current_date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    createNote: function() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      const id = this.$route.params.id

      axios.post(`/properties/${id}/notes.json`, {
        note: { content: this.note }
      }).then(response => {
        notyf.success('Property notes has been added!')
        this.fetchNotes()
        this.note = ''
      }).catch(error => {
        console.log(error)
        notyf.error('Oops, notes could not be created!')
      })
    },
    fetchNotes: function(){
      console.log('fetchNotes')
      const id = this.$route.params.id
      axios.get(`/properties/${id}/notes.json`)
        .then(response => {
          console.log(response.data.notes)
          this.notes = response.data.notes
        })
    },
    deleteNote: function(note){
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      const id = this.$route.params.id

      axios.delete(`/properties/${id}/notes/${note.id}.json`, {
        note: { content: this.note }
      }).then(response => {
        notyf.success('Note has been deleted!')
        this.fetchNotes()
      }).catch(error => {
        console.log(error)
        notyf.error('Oops, notes could not be deleted!')
      })
    },
    updateNote(note){
      let content = this.$refs[`editedNote${note.id}`][0].value
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      const id = this.$route.params.id

      axios.patch(`/properties/${id}/notes/${note.id}.json`, {
        note: { content: content }
      }).then(response => {
        notyf.success('Property note has been updated!')
        this.fetchNotes()
      }).catch(error => {
        console.log(error)
        notyf.error('Oops, note could not be updated!')
      })
    }
  },
  mounted() {
    this.fetchNotes()
  }
}
</script>

<style scoped>
.custom-icon {
  color: #767676;
}

ul {
  padding-left: 0px;
}

li {
  background-color: #FFF;
  color: #000;
  list-style-type: none;
}

.new-note {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.input-note {
  flex-grow: 1;
  padding-right: 10px;
}

.ml-2 {
  margin-left: 10px;
}
</style>