<template>
  <div class="mt-5" v-if="properties.length > 0">
    <b>Sponsor's Other Loans - {{ properties.length }} Loans</b>

    <table class="table-sm table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>City</th>
          <th>State</th>
          <th>Current Balance</th>
          <th>Delinquency Status</th>
          <th>Property Type</th>
          <th>Maturity Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="property in properties" :key="property.id">
          <td>
            <router-link :to="`/property/${property.id}`">
              {{ property.name }}
            </router-link>
          </td>
          <td>{{property.city}}</td>
          <td>{{stateName(property.state)}}</td>
          <td>${{commaDelimited(property.total_balance)}}</td>
          <td>{{property.delinquency_status}}</td>
          <td>{{typeName(property.normalized_property_type)}}</td>
          <td>{{toLocaleDate(property.loan.maturity_date)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios"
import states from '../json/states.json'
import {propertyTypes} from '../store'
const types = propertyTypes

export default {
  props: ['property'],
  data() {
    return {
      properties: []
    }
  },
  mounted() {
    this.fetchSponsorProperties();
  },
  methods: {
    fetchSponsorProperties: function(event) {
      const params = {
        sponsor: this.property.loan.sponsor
      }
      
      axios.get('/properties.json', { params })
      .then(response => {
        this.properties = response.data.properties.filter((property) => property.id != this.property.id)
      })
      .catch(error => {
        console.log(error);
      })
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }

      return value.toLocaleString();
    },
    stateName(stateKey) {
      return states[stateKey]
    },
    typeName(typeKey) {
      let prefix = typeKey.substring(0, 2)
      return types.find((x)=>x[0].startsWith(prefix))[1]
    },
    toLocaleDate(dateString) {
      if (dateString == undefined || dateString == null) {
        return dateString;
      }
            
      let date = new Date(`${dateString}T00:00:00Z`).toLocaleDateString('en-US', {timeZone: 'UTC'});
      
      return date; 
    }
  }
}
</script>

<style scoped>
  tr, td {
      font-size: 12px
  }
</style>