<template>
  <form class="search" @submit.prevent="search" ref="form">
    <div class="mb-3">
      <p class="mb-1 fw-bold">Property Name or Adddress</p>

      <input v-model="name" type="text" name="name" class="form-control">
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">Property Type</p>
      
      <select name="property_type" class="form-select" v-model="property_type" @change="search">
        <option v-for="(type) in $store.getters.allowedPropertyTypeFilters" :value="type[0]" :key="type[0]" selected="selected">
          {{ type[1] }}
        </option>
      </select>
    </div>
    <div class="mb-3" v-if="property_type && available_trepp_subtypes.length > 0">
      <p class="mb-1 fw-bold">Property Subtype</p>

      <label v-for="(subtype,index) in this.available_trepp_subtypes" :key="`trepp-${index}`">
        <input type="checkbox" :value="subtype" v-model="trepp_subtypes" @change="search">
        {{ capitalize(subtype) }}
      </label>
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">State</p>

      <multiselect v-model="multiple_state" :multiple="true" :options="optionsForMultiselect" :searchable="true" :close-on-select="true" track-by="code" label="name"  @input="stateChanged" placeholder="Select state"></multiselect>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Underwriting Status</p>
      
      <label>
        <input type="checkbox" v-model="select_all_statuses" @change="selectAllStatuses">
        (Select All)
      </label>
      
      <label v-for="(underwritingStatus,index) in this.underwritingStatuses" v-bind:key="index">
        <input type="checkbox" :value="underwritingStatus" v-model="status" @change="search">
        {{ capitalize(underwritingStatus) }}
      </label>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Delinquency Status</p>
      
      <label v-for="(delinquencyStatus,index) in this.delinquency_statuses" v-bind:key="index">
        <input type="checkbox" :value="delinquencyStatus" v-model="delinquency_status" @change="search">
        {{ delinquencyStatus }}
      </label>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Multiproperty Loan</p>
    
      <label>
        <input type="radio" v-model="multiproperty_loan" value="" @change="search">
        All
      </label>

      <label>
        <input type="radio" v-model="multiproperty_loan" value="Yes" @change="search">
        Yes
      </label>

      <label>
        <input type="radio" v-model="multiproperty_loan" value="No" @change="search">
        No
      </label>
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">Loan Status</p>
    
      <label>
        <input type="radio" v-model="loan_status" value="" @change="search">
        All
      </label>

      <label>
        <input type="radio" v-model="loan_status" value="special_serviced" @change="search">
        Special Serviced
      </label>

      <label>
        <input type="radio" v-model="loan_status" value="watchlist" @change="search">
        Watchlist
      </label>

      <label>
        <input type="radio" v-model="loan_status" value="performing" @change="search">
        Performing
      </label>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Political Affiliation</p>
    
      <label>
        <input type="radio" v-model="political_affiliation" value="" @change="search">
        All
      </label>

      <label>
        <input type="radio" v-model="political_affiliation" value="Republican" @change="search">
        Republican
      </label>

      <label>
        <input type="radio" v-model="political_affiliation" value="Democrat" @change="search">
        Democrat
      </label>
      
      <label>
        <input type="radio" v-model="political_affiliation" value="Swing" @change="search">
        Swing
      </label>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Debt Yield ( NOI )</p>
    
      <div class="input-group">
        <input type="text" v-model="debt_yield_noi.min" class="form-control" placeholder="Min" @input="search">
        <span class="input-group-text">to</span>
        <input type="text" v-model="debt_yield_noi.max" class="form-control" placeholder="Max" @input="search">
      </div>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Sq Footage</p>
    
      <div class="input-group">
        <input type="text" v-model="sq_footage.min" class="form-control" placeholder="Min">
        <span class="input-group-text">to</span>
        <input type="text" v-model="sq_footage.max" class="form-control" placeholder="Max">
      </div>
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">Min population within 5 miles</p>

      <input v-model="population" type="text" name="population" class="form-control" @change="search">
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Min Avg Income within 5 miles</p>

      <input v-model="income" type="text" name="income" class="form-control" @change="search">
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">Has Mezz</p>
      
      <label>
        <input type="checkbox" v-model="has_mezz" @change="search">
        Yes, loan has mezz from RCA
      </label>
    </div>
    
    <div class="mb-3">
      <p class="mb-1 fw-bold">Show Worsening Delinquency</p>
      
      <label>
        <input type="checkbox" v-model="declining_delinquency" @change="search">
        Yes, between last month and current month
      </label>
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">Commentary Date</p>

      <label>
        <input type="checkbox" v-model="newer_commentary_date" @change="search">
        Only show properties with commentary within 2 months old
      </label>
    </div>

    <div class="mb-3">
      <p class="mb-1 fw-bold">Servicer Commentary Mentioning</p>

      <label>
        <input type="radio" name="commentary" v-model="commentary" value="receiver" @change="search">
        Receiver
      </label>

      <label>
        <input type="radio" name="commentary" v-model="commentary" value="deed in lieu, dil, deed-in-lieu" @change="search">
        Deed in Lieu
      </label>

      <label>
        <input type="radio" name="commentary" v-model="commentary" value="consensual transfer" @change="search">
        Consensual Transfer
      </label>
      
      <label>
        <input type="radio" name="commentary" v-model="commentary" value="foreclosure" @change="search">
        Foreclosure
      </label>
      
      <label>
        <input type="radio" name="commentary" v-model="commentary" value="mezz, mezzanine" @change="search">
        Mezz Debt
      </label>
      
      <label>
        <input type="radio" name="commentary" v-model="commentary" value="month to month, mtm, month-to-month" @change="search">
        Month to Month
      </label>
      
      <label>
        <input type="radio" name="commentary" v-model="commentary" value="imminent default" @change="search">
        Imminent Default
      </label>
      
      <label>
        <input type="radio" name="commentary" v-model="commentary" value="cash management" @change="search">
        Cash Management
      </label>
      
      <a class="action-link" @click.prevent="uncheckAll">
        <small>Clear</small>
      </a>
    </div>
    
    <div class="mb-3">
      <a v-if="show_filter_detail" @click="toggleShowFilter" class="action-link">Hide filters</a>
      <a v-else @click="toggleShowFilter" class="action-link">Show more filters</a>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Loan Purpose</p>
    
      <label v-for="(loanPurpose,index) in this.loan_purposes" v-bind:key="index">
        <input type="checkbox" :value="loanPurpose" v-model="loan_purpose" @change="search">
        {{ loanPurpose }}
      </label>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Special Servicing Transfer Date</p>
      <b-form-datepicker :calendar-width="`400px`" v-model="special_servicing_transfer_date" @input="search"></b-form-datepicker>
    </div>

    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Watchlist Transfer Date</p>
      <b-form-datepicker :calendar-width="`400px`" v-model="watchlist_transfer_date" @input="search"></b-form-datepicker>
    </div>

    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Maturity Date</p>
      <b-form-datepicker :calendar-width="`400px`" v-model="maturity_date" @input="search"></b-form-datepicker>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Year Built</p>
    
      <div class="input-group">
        <input type="text" v-model="year_built.min" class="form-control" placeholder="Min">
        <span class="input-group-text">to</span>
        <input type="text" v-model="year_built.max" class="form-control" placeholder="Max">
      </div>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold"># Of Units</p>
    
      <div class="input-group">
        <input type="text" v-model="number_of_units.min" class="form-control" placeholder="Min">
        <span class="input-group-text">to</span>
        <input type="text" v-model="number_of_units.max" class="form-control" placeholder="Max">
      </div>
      
      <p>Applies to Multifamily or Hotels</p>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Rate</p>
    
      <div class="input-group">
        <input type="text" v-model="loan_rate.min" class="form-control" placeholder="Min">
        <span class="input-group-text">to</span>
        <input type="text" v-model="loan_rate.max" class="form-control" placeholder="Max">
      </div>
    </div>
      
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">DSCR (NOI)</p>
    
      <div class="input-group">
        <input type="text" v-model="dscr.min" class="form-control" placeholder="Min">
        <span class="input-group-text">to</span>
        <input type="text" v-model="dscr.max" class="form-control" placeholder="Max">
      </div>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Occupancy</p>
    
      <div class="input-group">
        <input type="text" v-model="occupancy.min" class="form-control" placeholder="Min">
        <span class="input-group-text">to</span>
        <input type="text" v-model="occupancy.max" class="form-control" placeholder="Max">
      </div>
    </div>
    
    <div class="mb-3" v-if="show_filter_detail">
      <p class="mb-1 fw-bold">Special Servicer</p>
    
      <input type="text" v-model="special_servicer" class="form-control">
    </div>
    
    <div v-if="show_filter_detail">
      <div class="mb-3">
        <p class="mb-1 fw-bold">Tenant</p>
      
        <input type="text" v-model="tenant" class="form-control">
      </div>
    
      <div class="mb-3">
        <p class="mb-1 fw-bold">Sponsor</p>
      
        <input type="text" v-model="sponsor" class="form-control" @change="search">
      </div>
    
      <div class="mb-3">
        <p class="mb-1 fw-bold">B-Piece Buyer</p>
      
        <input type="text" v-model="b_piece_buyer" class="form-control">
      </div>
    
      <div class="mb-3">
        <p class="mb-1 fw-bold">Box Size</p>
      
        <div class="input-group">
          <input type="text" v-model="box_sq_footage.min" class="form-control" placeholder="Min">
          <span class="input-group-text">to</span>
          <input type="text" v-model="box_sq_footage.max" class="form-control" placeholder="Max">
        </div>
      </div>

      <div class="mb-3">
        <p class="mb-1 fw-bold">CMBS Deal Trust Name</p>

        <input type="text" v-model="cmbs_deal_name" class="form-control" @change="search">
      </div>

      <div class="mb-3">
        <p class="mb-1 fw-bold">Total Balance</p>

        <div class="input-group">
          <input type="text" v-model="total_balance.min" class="form-control" placeholder="Min">
          <span class="input-group-text">to</span>
          <input type="text" v-model="total_balance.max" class="form-control" placeholder="Max">
        </div>
      </div>
    </div>
    
    <div class="my-4" v-if="show_filter_detail">
      <input type="submit" value="Submit" class="btn btn-primary">
      <a href="/properties">Clear Search</a>
    </div>
    
    <ImportFile action="uploads.json" title="Upload Properties" upload_type="Properties" />
    <ImportFile action="uploads.json" title="Validate Emails" upload_type="Emails" />
  </form>
</template>

<script>
import states from './json/states.json'
import axios from 'axios'
import { mapFields } from 'vuex-map-fields'
import { debounce } from 'lodash';
import Multiselect from 'vue-multiselect'
import ImportFile from './components/ImportFile.vue'
import Vue from 'vue'

export default {
  components: {
    ImportFile,
    Multiselect,
    Vue
  },
  props: [
    "statuses"
  ],
  data: function() {
    return {
      select_all_statuses: false,
      states: states,
      typed_state: "",
      filters: [],
      delinquency_statuses: [
        "Current",
        "Grace/Not yet due",
        "< 1 Month",
        "30 Days",
        "60 Days",
        "90+ Days",
        "Perf Mat Balloon",
        "NonPerf Mat Balloon",
        "Foreclosure",
        "REO"
      ],
      loan_purposes: [
        "Recapitalization/Refinance", 
        "Acquisition", 
        "Unknown", 
        "Acquisition/Refinance"
      ],
      multiple_state: [
        {
          code: "",
          name: "All"
        }
      ],
      show_filter_detail: false
    }
  },
  methods: {
    capitalize(string) {
      if (string == null || string.length == 0) {
        return ''
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    disabledDate: function(dateString, date) {
      if (date.getDate() == 31) {
        return false
      }
      return true
    },
    toggleShowFilter: function() {
      this.show_filter_detail = !this.show_filter_detail
    },
    search: function() {
      this.$store.dispatch('search')
    },
    uncheckAll: function() {
      this.$store.commit('clearCommentary')
      
      this.search()
    },
    selectAllStatuses: function() {
      if (this.select_all_statuses) {
        this.$store.commit('fillAllStatuses')
      } else {
        this.$store.commit('clearStatuses')
      }
      
      this.search()
    },
    checkAllStates: function() {
      this.$store.commit('fillAllStates')

      this.search()
    },
    uncheckAllStates: function() {
      this.$store.commit('clearStates')

      this.search()
    },
    stateChanged: function(selectedStates) {
      if (selectedStates.length == 0) {
        this.$store.commit('updateStates', [])
      } else {

        let codes = selectedStates.map((state) => state["code"])
        let validCodes = codes.filter((code)=> code != "")
        this.$store.commit('updateStates', validCodes)
      }

      this.search()
    }
  },
  watch: {
    name: function(newName, oldName) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "name", value: newName })
    },
    cmbs_deal_name: function(newCmbsDealName, oldCmbsDealName) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "cmbs_deal_name", value: newCmbsDealName })
    },
    special_servicer: function(newSpecialServicer, oldSpecialServicer) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "special_servicer", value: newSpecialServicer })
    },
    property_type: function(newPropertyType) {
      this.$store.commit('addPill', { key: "property_type", value: newPropertyType })
      this.$store.dispatch('loadTreppSubtypes')
    },
    state: function(newState) {
      this.$store.commit('addPill', { key: "state", value: newState })

      if (newState.length == 0) {
        this.multiple_state = [
          {
            code: "",
            name: "All"
          }
        ]
      } else {
        this.multiple_state = newState
          .filter((code) => code != "")
          .map(function(code) {
          return {
            code: code,
            name: states[code]
          }
        })
      }
    },
    delinquency_status: function(newDelinquencyStatus) {
      this.$store.commit('addPill', { key: "delinquency_status", value: newDelinquencyStatus })
    },
    multiproperty_loan: function(multiproperty_loan) {
      this.$store.commit('addPill', { key: "multiproperty_loan", value: multiproperty_loan })
    },
    status: function(newStatus) {
      this.$store.commit('addPill', { key: "status", value: newStatus })
    },
    trepp_subtypes: function(newSubtypes, oldSubtypes) {
      this.$store.commit('addPill', { key: "trepp_subtypes", value: newSubtypes })
    },
    loan_status: function(newLoanStatus) {
      this.$store.commit('addPill', { key: "loan_status", value: newLoanStatus })
    },
    loan_purpose: function(newLoanPurpose) {
      this.$store.commit('addPill', { key: "loan_purpose", value: newLoanPurpose })
    },
    maturity_date: function(newMaturityDate) {
      this.$store.commit('addPill', { key: "maturity_date", value: newMaturityDate })
    },
    "dscr.min": function(newDscrMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "dscr_min", value: newDscrMin })
    },
    "dscr.max": function(newDscrMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "dscr_max", value: newDscrMax })
    },
    tenant: function(newTenant) {
      this.$store.commit('addPill', { key: "tenant", value: newTenant })
    },
    sponsor: function(newSponsor) {
      this.$store.commit('addPill', { key: "sponsor", value: newSponsor })
    },
    b_piece_buyers: function(newBPieceBuyer) {
      this.$store.commit('addPill', { key: "b_piece_buyer", value: newBPieceBuyers })
    },
    "box_sq_footage.min": function(newSqFootageMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "box_sq_footage_min", value: newSqFootageMin })
    },
    "box_sq_footage.max": function(newSqFootageMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "box_sq_footage_max", value: newSqFootageMax })
    },
    "sq_footage.min": function(newSqFootageMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "sq_footage_min", value: newSqFootageMin })
    },
    "sq_footage.max": function(newSqFootageMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "sq_footage_max", value: newSqFootageMax })
    },
    "debt_yield_noi.min": function(newDebtYieldMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "debt_yield_noi_min", value: newDebtYieldMin })
    },
    "debt_yield_noi.max": function(newDebtYieldMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "debt_yield_noi_max", value: newDebtYieldMax })
    },
    political_affiliation: function(newPoliticalAffiliation) {
      this.$store.commit('addPill', { key: "political_affiliation", value: newPoliticalAffiliation })
    },
    population: function(newPopulation) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "population", value: Number(newPopulation).toLocaleString() })
    },
    income: function(newIncome) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "income", value: Number(newIncome).toLocaleString() })
    },
    declining_delinquency: function(newDecliningDelinquency) {
      this.$store.commit('addPill', { key: "declining_delinquency", value: newDecliningDelinquency })
    },
    newer_commentary_date: function(newNewerCommentaryDate) {
      this.$store.commit('addPill', { key: "newer_commentary_date", value: newNewerCommentaryDate })
    },
    has_mezz: function(newHasMezz) {
      this.$store.commit('addPill', { key: "has_mezz", value: newHasMezz })
    },
    "year_built.min": function(newYearBuiltMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "year_built_min", value: newYearBuiltMin })
    },
    "year_built.max": function(newYearBuiltMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "year_built_max", value: newYearBuiltMax })
    },
    "loan_rate.min": function(newRateMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "loan_rate_min", value: newRateMin })
    },
    "loan_rate.max": function(newRateMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "loan_rate_max", value: newRateMax })
    },
    "number_of_units.min": function(newNumberOfUnitsMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "number_of_units_min", value: newNumberOfUnitsMin })
    },
    "number_of_units.max": function(newNumberOfUnitsMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "number_of_units_max", value: newNumberOfUnitsMax })
    },
    "total_balance.min": function(newTotalBalanceMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "total_balance_min", value: newTotalBalanceMin })
    },
    "total_balance.max": function(newTotalBalanceMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "total_balance_max", value: newTotalBalanceMax })
    },
    "occupancy.min": function(newOccupancyMin) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "occupancy_min", value: newOccupancyMin })
    },
    "occupancy.max": function(newOccupancyMax) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "occupancy_max", value: newOccupancyMax })
    },
    commentary: function(newCommentary) {
      this.$store.commit('addPill', { key: "commentary", value: newCommentary })
    },
    special_servicing_transfer_date: function(newSpecialServicingTransferDate) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "special_servicing_transfer_date", value: newSpecialServicingTransferDate })
    },
    watchlist_transfer_date: function(newWatchlistTransferDate) {
      this.debouncedSearch()
      this.$store.commit('addPill', { key: "watchlist_transfer_date", value: newWatchlistTransferDate })
    },
  },
  created: function() {
    this.debouncedSearch = _.debounce(this.search, 100)
  },
  computed: {
    ...mapFields([
      'search.property_type',
      'search.trepp_subtypes',
      'search.name',
      'search.state',
      'search.status',
      'search.population',
      'search.income',
      'search.tenant',
      'search.commentary',
      'search.b_piece_buyer',
      'search.sq_footage',
      'search.box_sq_footage',
      'search.sponsor',
      'search.declining_delinquency',
      'search.newer_commentary_date',
      'search.delinquency_status',
      'search.has_mezz',
      'search.special_servicer',
      'search.dscr',
      'search.special_servicing_transfer_date',
      'search.loan_purpose',
      'search.maturity_date',
      'search.cmbs_deal_name',
      'search.total_balance',
      'search.loan_status',
      'search.occupancy',
      'search.watchlist_transfer_date',
      'search.multiproperty_loan',
      'search.political_affiliation',
      'search.number_of_units',
      'search.loan_rate',
      'search.year_built',
      'search.debt_yield_noi',
      'underwritingStatuses',
      'pills',
      'available_trepp_subtypes'
    ]),
    filteredStates: function() {
      if (this.typed_state == "") {
        return this.states
      }
      let result = {}
      for (let key in this.states) {
        if (this.states[key].toLowerCase().includes(this.typed_state.toLowerCase())) {
          result[key] = this.states[key]
        }
      }
      return result
    },

    optionsForMultiselect: function() {
      let result = []
      for (let key in this.states) {
        result.push({
          code: key,
          name: this.states[key]
        })
      }
      return result
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  form.search {
    input[type=text], select {
      font-size: 0.75rem;
    }
  
    p, label {
      font-size: 0.75rem;
    }
  
    p {
      margin-bottom: 0px;
    }
  
    label {
      display: block;
    }
  }
  
  a.action-link {
    cursor: pointer;
    color: #0f53fd;
  }
</style>