export const search = {
  name: '',
  state: [],
  status: [],
  tenant: '',
  sponsor: '',
  sort: 'properties.name',
  direction: 'asc',
  commentary: '',
  population: 0,
  income: 0,
  b_piece_buyer: '',
  property_type: '',
  trepp_subtypes: [],
  delinquency_status: [],
  declining_delinquency: false,
  newer_commentary_date: true,
  has_mezz: false,
  special_servicer: '',
  loan_status: '',
  political_affiliation: '',
  special_servicing_transfer_date: '',
  watchlist_transfer_date: '',
  loan_purpose: [],
  multiproperty_loan: '',
  maturity_date: '',
  year_built: {
    min: '',
    max: ''
  },
  loan_rate: {
    min: '',
    max: ''
  },
  occupancy: {
    min: '',
    max: ''
  },
  dscr: {
    min: '',
    max: ''
  },
  number_of_units: {
    min: '',
    max: ''
  },
  sq_footage: {
    min: '',
    max: ''
  },
  box_sq_footage: {
    min: '',
    max: ''
  },
  debt_yield_noi: {
    min: '',
    max: ''
  },
  cmbs_deal_name: '',
  total_balance: {
    min: '',
    max: ''
  },
}